import React from 'react';
import { Link } from 'components/shared';

export const HelpEmailLink: React.FC = () => {
  return (
    <Link to="mailto:help@tuune.com" display="inline">
      help@tuune.com
    </Link>
  );
};
