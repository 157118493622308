import React from 'react';
import { HelpEmailLink } from '../HelpEmailLink';
import { Par } from '../types';

export const DATA: { title: string; paragraphs: Par[] }[] = [
  {
    title: 'Description of Platform Service',
    paragraphs: [
      <>
        Uniq Health LLC (
        <b>
          <u>“Uniq”</u>
        </b>
        ) is the owner of Tuune 360, a clinical decision support tool that
        supports patients and health care professionals with educational
        information that can assist in choosing contraceptive options. Uniq also
        owns and operates the Tuune digital telemedicine platform (the{' '}
        <b>
          <u>“Tuune Platform”</u>
        </b>
        ) that facilitates the provision of medical services by medical
        professionals to users of the Tuune Platform (
        <b>
          <u>“Users”</u>
        </b>
        ). Users may also have prescriptions for contraceptives electronically
        sent to pharmacies through the Tuune Platform and may obtain laboratory
        services through the Tuune Platform. As part of operating the Tuune
        Platform on behalf of you, the medical professionals, the pharmacies,
        and the laboratories, Uniq requires access to your medical,
        prescription, and laboratory reports and records. Accordingly, users of
        the Tuune Platform are required to authorize the use and disclosure of
        their personal health information by and among Uniq, medical
        professionals, pharmacies, and laboratories. You are not required to
        sign this HIPAA Authorization, but you will not be able to access and
        utilize the services offered by the Tuune Platform without signing this
        HIPAA Authorization.
      </>,
    ],
  },
  {
    title: 'HIPAA Authorization',
    paragraphs: [
      <>
        I hereby authorize Uniq LLC (
        <b>
          <u>“Uniq”</u>
        </b>
        ) to use and disclose my protected health information (
        <b>
          <u>“PHI”</u>
        </b>
        ) that I provide through the Tuune Platform, including health
        assessments, to medical professionals, laboratories, and pharmacies who
        provide services to me through the Tuune Platform (collectively,{' '}
        <b>
          <u>“Providers”</u>
        </b>
        ), and for Providers to use and disclose my PHI to each other and to
        Uniq in connection with services that I receive through the Tuune
        Platform, and to enable Uniq to provide administrative services to
        Providers in connection with their use of the Tuune Platform and
        provision of medical services to Users. This includes, for example, use
        and disclosure of my medical and health questionnaires, medical
        consultation records, prescriptions, laboratory tests and results, and
        correspondence between me, Uniq, and Providers. This authorization shall
        remain in effect until I no longer utilize the Tuune Platform. I
        understand that I will not receive services from Providers through the
        Tuune Platform unless I sign this Authorization. I understand that I
        have a right to revoke this Authorization, in writing, at any time,
        except to the extent that Uniq and Providers have acted in reliance upon
        it, by sending a written notification to <HelpEmailLink />. I understand
        that PHI used or disclosed pursuant to this Authorization may be
        redisclosed by the recipient and its confidentiality may no longer be
        protected by federal or state law.
      </>,
      <>
        <b>
          By checking this box, I attest that I authorize the use and disclosure
          of my PHI as described above.
        </b>
      </>,
    ],
  },
];
