import React from 'react';
import { GridContainer, GridItem, H2 } from '@tuunetech/tuune-components';
import { HipaaConsentContainer } from './styles';
import { DATA } from './data';
import { Paragraphs, Title } from '../components';

export const HipaaConsentLayout: React.FC = () => {
  return (
    <HipaaConsentContainer>
      <GridContainer justifyContent="center">
        <GridItem lg={8}>
          <H2 align="center" $marginBottom={40}>
            HIPAA AUTHORIZATION TO USE AND DISCLOSE PROTECTED HEALTH INFORMATION
          </H2>
          {DATA.map(({ title, paragraphs }) => (
            <React.Fragment key={title}>
              <Title title={title} />
              <Paragraphs paragraphs={paragraphs} />
            </React.Fragment>
          ))}
        </GridItem>
      </GridContainer>
    </HipaaConsentContainer>
  );
};
